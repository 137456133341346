<template>
  <v-container fluid>
    <!--HEADER-->
    <v-app-bar color="#3F9D2A" flat app dense height="40px" dark>
      <v-btn icon :to="{ name: 'rda' }">
        <v-icon>mdi-home-outline</v-icon>
      </v-btn>
      <v-toolbar-title>Servicios del Complejo</v-toolbar-title>      
    </v-app-bar> 
    <v-card>
       <v-card-title class="ma-0 pa-0" >
                       <v-icon color="green" class="ma-0 pa-0" large>mdi-menu-right</v-icon>
                        Amenities
          </v-card-title>
           <v-divider class="ma-0 mb-3"></v-divider>
      
        <v-row class="ma-3">
          <v-col class="ma-0 pa-0 mx-4" v-for="(item, i) in itemAmenitiesCategoria" :key="i" cols="12">            
                <div class="title px-4">{{item.titulo}}
                </div>      
                <div class="d-flex">
                      <v-avatar class="mx-5 mt-0 pa-2" tile>
                            <v-img :src="item.icono" contain=true></v-img>
                      </v-avatar>
                      <v-row dense>
                              <v-col dense class="caption ma-0 pa-0" cols="12"  v-for="(subitem,k) in filtroPorCategoria(item.id)" :key="`${k}-${subitem.idcategoria}`"
                                      v-html=subitem.titulo > 
                              </v-col>                              
                      </v-row>                            
                  </div>                
                    <v-container fluid>
                      <v-divider class="ma-0 mr-6"></v-divider>
                    </v-container>
          </v-col>
        </v-row>
 <v-card-title class="ma-0 pa-0" >
                       <v-icon color="green" class="ma-0 pa-0" large>mdi-menu-right</v-icon>
                        Actividades en el complejo
          </v-card-title>
           <v-divider class="ma-0 mb-3"></v-divider>
           
      <v-row class="ma-3">
          <v-col class="ma-0 pa-0 mx-4" v-for="(item, i) in itemActividadesCategoria" :key="i" cols="12">            
                <div class="title px-4">{{item.titulo}}
                </div>      
                <div class="d-flex">
                      <v-avatar class="mx-5 mt-0 pa-2" tile>
                            <v-img :src="item.icono" contain=true></v-img>
                      </v-avatar>
                      <v-row dense>
                              <v-col dense class="caption ma-0 pa-0" cols="12"  v-for="(subitem,k) in filtroPorCategoriaB(item.id)" :key="`${k}-${subitem.idcategoria}`"
                                      v-html=subitem.titulo > 
                              </v-col>                              
                      </v-row>                            
                  </div>                
                    <v-container fluid>
                      <v-divider class="ma-0 mr-6"></v-divider>
                    </v-container>
          </v-col>
        </v-row>
    </v-card>       
  </v-container>
</template>
<script>

export default {
  name:"serviciosrde",
  data() {
    return { 
        itemAmenitiesCategoria: [
                                  { id:'1',
                                    titulo:'',
                                    icono:require('../assets/ubicacion.gif')
                                  },
                                  { id:'2',
                                    titulo:'Apartamentos',
                                    icono:require('../assets/casa.gif')
                                  },
                                  { id:'3',
                                    titulo:'Room Service',
                                    icono:require('../assets/room.gif')
                                  },
                                  { id:'4',
                                    titulo:'Club House',
                                    icono:require('../assets/juegos.gif')
                                  },
                                   
                                  { id:'6',
                                    titulo:'Piscinas',
                                    icono:require('../assets/piscina.gif')
                                  },
                                  { id:'7',
                                    titulo:'Rincón Infantil',
                                    icono:require('../assets/lapiz.gif')
                                  },
                                  
                                  { id:'9',
                                    titulo:'Deportes',
                                    icono:require('../assets/tennis.gif')
                                  },
                                   { id:'10',
                                    titulo:'Excursiones',
                                    icono:require('../assets/cartel.gif')
                                  },
                                   {
                                   id:'11',
                                    titulo:'Gastronomias',
                                    icono:require('../assets/gourmet.gif')
                                  }
                        ],
        itemAmenities: [
                                  { idcategoria:'1',
                                    titulo:'Recepción las 24 horas'
 
                                  },
                                  { idcategoria:'1',
                                    titulo:'Personal bilingue'                                    
                                  },
                                  { idcategoria:'1',
                                    titulo:'Estacionamiento'                                    
                                  },
                                  { idcategoria:'1',
                                    titulo:'Traslados desde y hacia el Aeropuerto y Cerro Chapelco. Servicio tercerizado con cargo'                                    
                                  },
                                  { idcategoria:'2',
                                    titulo:'Confortables unidades con capacidad para 2, 3, 4, 6 y 8 personas'                                    
                                  },
                                  { idcategoria:'2',
                                    titulo:'Totalmente equipadas con vajilla completa'                                    
                                  },
                                  { idcategoria:'2',
                                    titulo:'Ropa blanca'                                    
                                  },
                                  { idcategoria:'2',
                                    titulo:'Amplios bow windows con vista al parque y la montaña'                                    
                                  },                                  
                                  { idcategoria:'2',
                                    titulo:'Wi-Fi'                                    
                                  },




                                  { idcategoria:'3',
                                    titulo:'Servicio de mucamas'                                    
                                  },
                                  { idcategoria:'3',
                                    titulo:'Lavandería'                                    
                                  },
                                    { idcategoria:'3',
                                    titulo:'Room service'                                    
                                  },                                
                                  { idcategoria:'4',
                                    titulo:'Bar'                                    
                                  },
                                  { idcategoria:'4',
                                    titulo:'Sala de juegos y conferencias'                                    
                                  },
                                  { idcategoria:'4',
                                    titulo:'Wi-Fi'                                    
                                  },
                                  { idcategoria:'6',
                                    titulo:'Cubierta climatizada con solárium'                              
                                  },
                                  { idcategoria:'6',
                                    titulo:'Vestuarios'                                    
                                  },
                                  { idcategoria:'6',
                                    titulo:'Saunas'                                    
                                  },                                 
                                  
                                  { idcategoria:'7',
                                    titulo:'Sala de Juegos para los más chicos'                                    
                                  },
                                  
                                   { idcategoria:'9',
                                    titulo:'Cancha de paddle'                                    
                                  },
                                   { idcategoria:'9',
                                    titulo:'Nuevo Gimnasio equipado'                                    
                                  },
                                   
                                  { idcategoria:'10',
                                    titulo:'Esquí'                                   
                                  },
                                  { idcategoria:'10',
                                    titulo:'Turismo Aventura'                                    
                                  },
                                  { idcategoria:'10',
                                    titulo:'Pesca con Mosca'                                    
                                  },
                                  { idcategoria:'10',
                                    titulo:'4 x 4'                                    
                                  },
                                  { idcategoria:'10',
                                    titulo:'Rafting'                                    
                                  },
                                  { idcategoria:'10',
                                    titulo:'Actividades recreativas y turísticas para toda la familia'                                    
                                  },
                                  { idcategoria:'11',
                                    titulo:'Restaurante RINCÓN GOURMET'                                    
                                  },
                                  { idcategoria:'11',
                                    titulo:'Comida regional patagónica'                                    
                                  },
                                  { idcategoria:'11',
                                    titulo:'Cava de vinos'                                   
                                  },
                                   { idcategoria:'11',
                                    titulo:'Hogar a leña'                                   
                                  }
                        ],
              itemActividadesCategoria: [
                                  { id:'1',
                                    titulo:'Deportes',
                                    icono:require('../assets/tennis.gif')
                                  },
                                  { id:'2',
                                    titulo:'Entretenimiento',
                                    icono:require('../assets/microfono.gif')
                                  },
                                  { id:'3',
                                    titulo:'Juegos',
                                    icono:require('../assets/juegos.gif')
                                  },
                                  { id:'4',
                                    titulo:'Rincón Infantil',
                                    icono:require('../assets/lapiz.gif')
                                  },
                                   { id:'5',
                                    titulo:'Clases ',
                                    icono:require('../assets/reloj.gif')
                                  }
                        ],
        itemActividades: [                                
                                  { idcategoria:'1',
                                    titulo:'Piscina cubierta climatizada'                                    
                                  },
                                  { idcategoria:'1',
                                    titulo:'Paddle'                                    
                                  },
                                  { idcategoria:'1',
                                    titulo:'Ping Pong'                                    
                                  },
                                  { idcategoria:'1',
                                    titulo:'Voley (piscina)'                                    
                                  },
                                  { idcategoria:'1',
                                    titulo:'Waterpolo (piscina)'                                    
                                  },
                                  { idcategoria:'1',
                                    titulo:'Futbol siamés (en verano)'                                    
                                  },                                  
                                  { idcategoria:'2',
                                    titulo:'Copa de Bienvenida el día sábado'                                    
                                  },
                                  { idcategoria:'2',
                                    titulo:'Festejo del Huésped con show en vivo'                                    
                                  },
                                  { idcategoria:'2',
                                    titulo:'Noche de cine'                                    
                                  },
                                   { idcategoria:'2',
                                    titulo:'Noche de Canto Bar Temático'                                    
                                  },
                                   { idcategoria:'2',
                                    titulo:'Noche de despedida con show en vivo'                                    
                                  },
                                   { idcategoria:'2',
                                    titulo:'Biblioteca'                                    
                                  },
                                   { idcategoria:'2',
                                    titulo:'Fogones familiares'                                    
                                  },
                                  { idcategoria:'3',
                                    titulo:'Bingo musical y tradicional'                                    
                                  },
                                  { idcategoria:'3',
                                    titulo:'Torneo de truco, chinchón y burako'                                    
                                  },
                                  { idcategoria:'3',
                                    titulo:'Juegos de azar'                                    
                                  },
                                  { idcategoria:'3',
                                    titulo:'Actividades familiares. Ej: “Kermesse”'                                    
                                  },
                                  { idcategoria:'3',
                                    titulo:'Juegos de puntería'                                    
                                  },
                                  { idcategoria:'3',
                                    titulo:'Metegol'                                    
                                  },
                                  { idcategoria:'3',
                                    titulo:'Pool'                                    
                                  },
                                  { idcategoria:'3',
                                    titulo:'Juegos de mesa'                                    
                                  },
                                  { idcategoria:'3',
                                    titulo:'Tejo'                                    
                                  },
                                  { idcategoria:'3',
                                    titulo:'Juegos con agua (en verano)'                                    
                                  },
                                  { idcategoria:'4',
                                    titulo:'Sala de juegos'                                    
                                  },
                                  { idcategoria:'4',
                                    titulo:'Manualidades'                                    
                                  },
                                  { idcategoria:'4',
                                    titulo:'Talleres para chicos'                                    
                                  },
                                  { idcategoria:'4',
                                    titulo:'Proyecciones infantiles'                                    
                                  },
                                  { idcategoria:'5',
                                    titulo:'Aquagym'                                    
                                  },
                                  { idcategoria:'5',
                                    titulo:'Stretching (piscina)'                                    
                                  }
                        ]
    }
  },
  methods:{
            filtroPorCategoria(id){
                                   let resultado=[]
                                   resultado=this.itemAmenities
                                    resultado = resultado.filter(function(item) {
                                    return item.idcategoria === id
                                    })
                                   return resultado           
            },
            filtroPorCategoriaB(id){
                                   let resultado=[]
                                   resultado=this.itemActividades
                                    resultado = resultado.filter(function(item) {
                                    return item.idcategoria === id
                                    })
                                   return resultado           
            }
          }    
 }
</script>